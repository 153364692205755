<template>
    <div class="row-12 justify-center">
            <q-banner
                v-if="mostrarMensagem"
                class="text-center rounded"
                :class="`${classe} ${uppercase ? 'text-uppercase' : ''} ${tipo == 'bg-warning' ? `bg-warning  ${cor_texto}` : `${$q.dark.isActive ? 'bg-dark text-white' : `${tipo} ${cor_texto}`}`}`">
                {{ mensagem }}
            </q-banner>
            <slot></slot>
        </div>
</template>

<script>
export default {
    name: 'BannerMensagem',

    props: {
        mostrarMensagem: {
            type: Boolean,
            default: true
        },
        mensagem: {
            type: String,
            required: true
        },
        tipo: {
            type: String,
            default: 'bg-warning'
        },
        cor_texto: {
            type: String,
            default: 'text-black'
        },
        classe: {
            type: String,
            default: ''
        },
        uppercase: {
            type: Boolean,
            default: true
        },
    }
}
</script>

<style>
</style>
